import axios from "axios";

const API_URL = 'api/quiz/newquizrecord';

// Creat new mealplan record
const newQuizRecord = async (quizData) => {
    const response = await axios.post(API_URL, quizData);
    return response.data;
}

const quizService = {
    newQuizRecord
}

export default quizService;