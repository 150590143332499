import * as React from "react";
import { AreaChart, XAxis, YAxis, Area, ResponsiveContainer,} from 'recharts';
import {SurveyProgress, WeightLossChartStats} from '../../../components';

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    .chart-container {
        width: 100%;
    }    
    }`;

const palette = [ "#E94649", "#F6B53F" ]; 

function WeightLossChart({
  metric,
  weightKgCurr,
  weightKgTarget,
  weightLbsCurr,
  weightLbsTarget,
  step,
  nextStep,
  heightCm,
  heightFeet,
  heightInches
}) {

    const today = new Date();

    const monthlyLoss = metric ? (weightKgCurr - weightKgTarget) / 9 : (weightLbsCurr - weightLbsTarget)/9;
    const currentWeight = metric ? weightKgCurr : weightLbsCurr;
    const targetWeight = metric ? weightKgTarget : weightLbsTarget;

    const minimumChartPlot = targetWeight - 1;
    const maximumChartPlot = currentWeight + 1;

    let newWeight = currentWeight;
    let weightLostArray = [];

    // Pounds lost on keto
    for (let i = 0; i<11; i++){

      if (i >= 0 && i < 2 ) {
        newWeight = (newWeight - monthlyLoss) + (i*4.5)
      } 
      else if (i >= 2 && i < 7 ) {
        newWeight = (newWeight - monthlyLoss) - (i*3.5)
      } 
      else if (i >= 7 && i < 9 ) {
        newWeight = (newWeight - monthlyLoss) + (i*.25)
      }
      // else if (i > 2 && i < 5) {
      //   newWeight = (newWeight - monthlyLoss) - (i*0.25)
      // }
      else {
        newWeight = newWeight - monthlyLoss
      }

      weightLostArray.push(newWeight);
   }

    
    // Plot weight loss with keto projection
    const ketoWeightLossData = Array.from({length: 9}, (_, i) => {
      let date = new Date(today.getFullYear(), today.getMonth() + i, 1);
      let formattedDate = date.toLocaleString('en-US', { month: 'short' });
      return { "name": formattedDate, "uv": weightLostArray[i]};
    })

    // Create goal a date for weight loss to display to user
    var goalDate = ketoWeightLossData[7].name.toLocaleString('en-US', { month: 'long', year: 'numeric'})
    console.log('Goal date ',goalDate)
    console.log('KetoWeightLoss data ', ketoWeightLossData)

    const handleNextStep = (stepValue)=>{
      nextStep(stepValue)
    }

    return (
      <>
      <div className="control-pane">
        <SurveyProgress step={step}/>
        <style>{SAMPLE_CSS}</style>
        <div className="control-section mt-7 shadow-md">
          <div className="bg-white">
            <h1 className='mt-4 text-center text-3xl'>Based on your answers, you can be </h1>
            {metric ? (
              <h1 className="mx-4 text-center text-4xl font-bold tracking-tight text-red-600 sm:text-6xl">
                {weightKgTarget} <span className="text-3xl">kg</span>  
              </h1>
            ) : (
              <h1 className="mx-4 text-center text-4xl font-bold tracking-tight text-red-600 sm:text-6xl">
                {weightLbsTarget} <span className="text-3xl">lb</span>
              </h1>
            )}

            <h1 className="mt-4 text-center text-3xl">by {goalDate}</h1>
          </div>
          <ResponsiveContainer width="100%" height={300}> 
            <AreaChart 
              // width={700} 
              // height={300} 
              data={ketoWeightLossData}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5
              }}>
   
            <XAxis hide="true" dataKey="name" padding={{ left: 20, right: 20 }}/>
            <YAxis hide="true" domain={[minimumChartPlot, maximumChartPlot]}/>
          {/* <Tooltip /> */}
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
            </ResponsiveContainer>
          <p className="bg-white text-sm text-gray-400 p-2">
            This is not a customized graph. Please consult with medical professional 
            before beginning any weight loss, diet, exercise, or other health program.
            Estimation made base on a study of long-term effects of a ketodiet in obese 
            patients published in fall 2004.
          </p>
          
        </div>
        {/* ++++++++++++++++++++++++++++ */}
        <WeightLossChartStats
          metric={metric}
          weightKgCurr={weightKgCurr}
          weightLbsCurr={weightLbsCurr}
          heightCm={heightCm}
          heightFeet={heightFeet}
          heightInches={heightInches}
        />        
    {/* ++++++++++++++++++++++++++++++++++++ */}          
      </div>
      <div>
        <button
            type="submit"
            // disabled={disabled}
            onClick={()=> handleNextStep(step)}
            className="flex w-full h-12 justify-center rounded-md bg-orange-600 px-3 mt-12 py-2 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
            >
            CONTINUE
        </button>
      </div>
      </>
    );
  
    function onChartLoad(args) {
      let chart = document.getElementById("charts");
      chart.setAttribute("title", "");
    }
    
    function load(args) {
        let selectedTheme = window.location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
    }

}

export default WeightLossChart;