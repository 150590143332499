import axios from "axios";

const API_URL = 'api/optin/newoptin';

// Creat new optin record
const newOptinRecord = async (optinData) => {
    const response = await axios.post(API_URL, optinData);
    // Save optinId to localStorage
    if (response.data){
        localStorage.setItem('optin', JSON.stringify(response.data.newNewOptinRecord.optinId))
    }

    return response.data;
}

const quizService = {
    newOptinRecord
}

export default quizService;