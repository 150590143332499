import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import buyerReducer from '../features/buyer/buyerSlice';
import mealplanReducer from '../features/mealplan/mealplanSlice';
import recipesReducer from '../features/recipes/recipesSlice';
import optinReducer from '../features/optin/optinSlice';
import resetReducer from '../features/reset/resetSlice'; 

export const store = configureStore({
    reducer: {
        auth: authReducer,
        buyer: buyerReducer,
        mealplan: mealplanReducer,
        recipes: recipesReducer,
        optin: optinReducer,
        reset: resetReducer
    }
})