import * as React from "react";
import { AreaChart, XAxis, YAxis, Area, ResponsiveContainer, } from 'recharts';
import {SurveyProgress, WeightLossChartStats} from '../../../components';

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    .chart-container {
        width: 100%;
    }    
    }`;

const palette = [ "#E94649", "#F6B53F" ]; 

function WeightLossChart1({
  metric,
  gender,
  weightKgCurr,
  weightKgTarget,
  weightLbsCurr,
  weightLbsTarget,
  step,
  nextStep,
  heightCm,
  heightFeet,
  heightInches
}) {

    function quadraticWeightLossPlanV2(currentWeight, targetWeight, totalMonths = 9) {
        let vertexX = totalMonths;
        let vertexY = (currentWeight + targetWeight) / 2;
        let a = (currentWeight - vertexY) / (0 - vertexX) ** 2;
        
        let plan = [];
        
        for (let month = 1; month <= totalMonths; month++) {
            let predictedWeight = a * (month - vertexX) ** 4 + vertexY;
            if (predictedWeight < targetWeight) {
                predictedWeight = targetWeight;
            }
            plan.push({
                weight: parseFloat(predictedWeight.toFixed(2)),
                month: month
            });
        }
        
        return plan;
    }

    const today = new Date();
    const currentWeight = metric ? weightKgCurr : weightLbsCurr;
    const targetWeight = metric ? weightKgTarget : weightLbsTarget;

    const minimumChartPlot = targetWeight - 2;
    const maximumChartPlot = currentWeight + 2;

    const weightPlan = quadraticWeightLossPlanV2(currentWeight, targetWeight);

    const ketoWeightLossData = weightPlan.map((entry, index) => {
        const date = new Date(today.getFullYear(), today.getMonth() + index, 1);
        let formattedDate = date.toLocaleString('en-US', { month: 'short' });
        return { "name": formattedDate, "uv": entry.weight };
    });

    const goalDate = ketoWeightLossData[7].name.toLocaleString('en-US', { month: 'long', year: 'numeric'})

    const handleNextStep = (stepValue)=>{
      nextStep(stepValue)
    }

    return (
        <>
        <div className="control-pane">
          <SurveyProgress step={step}/>
          <style>{SAMPLE_CSS}</style>
          <div className="control-section mt-7 shadow-md">
            <div className="bg-white">
              <h1 className='mt-4 text-center text-3xl'>Based on your answers, it's possible for you to reach your goal </h1>
              {metric ? (
                <h1 className="mx-4 text-center text-4xl font-bold tracking-tight text-red-600 sm:text-6xl">
                  {weightKgTarget} <span className="text-3xl">kg</span>  
                </h1>
              ) : (
                <h1 className="mx-4 text-center text-4xl font-bold tracking-tight text-red-600 sm:text-6xl">
                  {weightLbsTarget} <span className="text-3xl">lb</span>
                </h1>
              )}
  
              <h1 className="mt-4 text-center text-3xl">by {goalDate}</h1>
            </div>
            <div className="px-5">
            <ResponsiveContainer width="100%" height={300}>     
              <AreaChart 
                data={ketoWeightLossData}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5
                }}>
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                 <XAxis  dataKey="name"/> 
                 <YAxis hide="true" domain={[minimumChartPlot, maximumChartPlot]}/>
                         
              </AreaChart>
              </ResponsiveContainer>
              </div>
            <p className="bg-white text-sm text-gray-400 p-2">
              This is not a customized graph. Please consult with medical professional 
              before beginning any weight loss, diet, exercise, or other health program.
              Estimation made base on a study of long-term effects of a keto diet in obese 
              patients published in fall 2004.
            </p>
            
          </div>
          {/* ++++++++++++++++++++++++++++ */}
          <WeightLossChartStats
            metric={metric}
            gender={gender}
            weightKgCurr={weightKgCurr}
            weightLbsCurr={weightLbsCurr}
            heightCm={heightCm}
            heightFeet={heightFeet}
            heightInches={heightInches}
          />        
      {/* ++++++++++++++++++++++++++++++++++++ */}          
        </div>
        <div>
          <button
              type="submit"
              // disabled={disabled}
              onClick={()=> handleNextStep(step)}
              className="flex w-full h-12 justify-center rounded-md bg-orange-600 px-3 mt-12 py-2 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
              >
              CONTINUE
          </button>
        </div>
        </>
    );
}

export default WeightLossChart1;
