import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import optinService from './optinService';

const initialState = {
    // if user is set in localStorage save as user else set as empty string
    // user: user ? user : '',
    data : null,
    isError: false,
    isSuccess: false,
    isLoading:false, 
    message: ''
}

// Optin Thunk
export const newOptin = createAsyncThunk('optin/new',
    async (optinData, thunkAPI) => {
        try{
            return await optinService.newOptinRecord(optinData)
        }catch(error){
            const message = (error.response && 
                            error.response.data && 
                            error.response.data.message) || 
                            error.message ||
                            error.toString()
            
            return thunkAPI.rejectWithValue(message)                
        }
})

export const optinSlice = createSlice({
    name: 'optin',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(newOptin.pending, (state) => {
                state.isLoading = true
            })
            .addCase(newOptin.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(newOptin.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.data = ''
            })
    }
});

export const { reset } = optinSlice.actions;
export default optinSlice.reducer;
