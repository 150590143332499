import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'

import App from './App';
import { store } from './app/store'
import { Provider } from 'react-redux';
import { ContextProvider } from './contexts/ContextProvider';

/******** Google tag manager *********/
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-WK7VF6CX'
}

TagManager.initialize(tagManagerArgs)
/*************************************/

ReactDOM.render(
    <Provider store={store}>
        <ContextProvider>
            <App />
        </ContextProvider>
    </Provider>
, document.getElementById('root'));