//https://www.youtube.com/watch?v=e-whXipfRvg&t=269s

import React, {useState, } from 'react';
import { Disclosure } from '@headlessui/react'
// import { LockClosedIcon, } from '@heroicons/react/20/solid'
import { 
  CheckoutSteps, 
  ContactForm, 
  PaymentFormWraperSubscribe } from '../components'

import eatToBurnProductImage from '../assets/img/product/EatToBurnGuide72_300.png'
import foolProofDietImage from '../assets/img/bonuses/thefoolproofdiet_comp1.jpg'
import bulletproofKetoImage from '../assets/img/bonuses/image14.jpg'
import hackingFitness from '../assets/img/product/HackingFitnessGuide.png'

const bumpDetails = {
  name: 'Hacking Fitness 101',
  price: 9.98,
  originalPrice: '$38.98',
  imageSrc: hackingFitness,
}
const products = [
  {
    id: 1,
    name: 'Eat To Burn Mealplan',
    price: 34.89,
    originalPrice: '$96.00',
    imageSrc: eatToBurnProductImage,
  },
  {
    id: 2,
    name: 'Foolproof Diet',
    price: 'Free',
    originalPrice: '$96.00',
    imageSrc: foolProofDietImage,
  },
  {
    id: 3,
    name: 'Bulletproof Keto',
    price: 'Free',
    originalPrice: '$96.00',
    imageSrc: bulletproofKetoImage,
  },
  // More products...
]


export default function CheckoutSubscribe() {
  /** Adds Order bump to the list of items the cart ****/
  const [bump, setBump] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  // const [stripePromise, setStripePromise] = useState(null);
  // const [clientSecret, setClientSecret] = useState("");

  const handleBumpChange = () => {
    setBump(!bump);
  };

  const handleStepChange = () => {
    setCurrentStep(currentStep + 1);
  }

  const total = (bump) => {
    let totalPrice;
    if (bump){
      totalPrice = bumpDetails.price + products[0].price;
    } else {
      totalPrice = products[0].price;
    }
    return totalPrice;
  }

  return (
    <>
      <main className="lg:px-20 lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
        <h1 className="sr-only">Checkout</h1>

        {/* Mobile order summary */}
        <section aria-labelledby="order-heading" className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden">
          <Disclosure as="div" className="mx-auto max-w-lg">
            {({ open }) => (
              <>
                <div className="flex items-center justify-between">
                  <h2 id="order-heading" className="text-lg font-medium text-gray-900">
                    Your Order
                  </h2>
                  <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                    {open ? <span>Hide full summary</span> : <span>Show full summary</span>}
                  </Disclosure.Button>
                </div>

                <Disclosure.Panel>
                  <ul className="divide-y divide-gray-200 border-b border-gray-200">
                  {bump &&             
                      <li className="flex space-x-6 py-6">
                        <img
                          src={bumpDetails.imageSrc}
                          alt=''
                          className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                        />
                        <div className="flex flex-col justify-between space-y-4">
                          <div className="space-y-1 text-md font-semibold">
                            <h3 className="text-gray-900">{bumpDetails.name}</h3>
                            <p className='text-green-900'>
                              <span className='line-through text-red-900 mr-3'>{bumpDetails.originalPrice}</span>
                              ${bumpDetails.price}</p>
                          </div>
                          
                        </div>
                      </li>}
                    {products.map((product) => (
                      <li key={product.id} className="flex space-x-6 py-6">
                        <img
                          src={product.imageSrc}
                          alt={product.imageAlt}
                          className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                        />
                        <div className="flex flex-col justify-between space-y-4">
                          <div className="space-y-1 text-md font-medium">
                            <h3 className="text-gray-900">{product.name}</h3>
                            <p className="text-green-900">
                            <span className='line-through text-red-900 mr-3'>{product.originalPrice}</span>
                              {product.id === 1 ? '$' : ''}{product.price}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>

                <p className="mt-6 flex items-center justify-between pt-6 text-sm font-medium text-gray-900">
                  <span className="text-base">Total</span>
                  <span className="text-base">${total(bump).toFixed(2)}</span>
                </p>
              </>
            )}
          </Disclosure>
        </section>

        {/* Order summary */}
        <section aria-labelledby="summary-heading" className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex">

          <ul className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6">
            {bump &&             
              <li className="flex space-x-6 py-6">
                <img
                  src={bumpDetails.imageSrc}
                  alt=''
                  className="h-20 w-20 flex-none rounded-md bg-gray-200 object-cover object-center"
                />
                <div className="flex flex-col justify-between space-y-4">
                  <div className="space-y-1 text-md font-semibold">
                    <h3 className="text-gray-900">{bumpDetails.name}</h3>
                    <p className='text-green-900'>
                      <span className='line-through text-red-900 mr-3'>{bumpDetails.originalPrice}</span>
                      ${bumpDetails.price}</p>
                  </div>
                  
                </div>
              </li>}

            {products.map((product) => (
              <li key={product.id} className="flex space-x-6 py-6">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-20 w-20 flex-none rounded-md bg-gray-200 object-cover object-center"
                />
                <div className="flex flex-col justify-between space-y-4">
                  <div className="space-y-1 text-md font-semibold">
                    <h3 className="text-gray-900">{product.name}</h3>
                    <p className='text-green-900'>
                      <span className='line-through text-red-900 mr-3'>{product.originalPrice}</span>
                      ${product.price}</p>
                  </div>
                  
                </div>
              </li>
            ))}
          </ul>

          <div className="sticky bottom-0 flex-none bg-gray-50 p-4">
            <dl className="mt-2 space-y-5 text-lg font-medium text-gray-500">
              <div className="flex items-center justify-between border-t border-gray-200 pt-2 text-gray-900">
                <dt>Total</dt>
                <dd className="text-base">$ {total(bump).toFixed(2)}</dd>
              </div>
            </dl>
          </div>
        </section>

        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 pt-2 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
        >

          <div className="min-w-lg lg:px-10 lg:pt-16">
            {
              <>
              <CheckoutSteps step={currentStep}/>
              {
                currentStep === 1 
                  ? 
                  <ContactForm 
                    step={currentStep}
                    onStepChange={handleStepChange}
                    setName={setName}
                    setEmail={setEmail}
                  />
                  : 
                  <PaymentFormWraperSubscribe
                    bump={bump}
                    name={name}
                    email={email}
                    onBumpChange={handleBumpChange}
                    onStepChange={handleStepChange}
                  />
              }
              
              </>
            }
          </div>
        </section>
      </main>
    </>
  )
}