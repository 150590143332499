import {useEffect, useState} from 'react';
import { Elements, } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import {PaymentFormSubscribe} from '../../components';

// const serverSideBaseAPI = 'http://localhost:5000/api';
const serverSideBaseAPI = process.env.REACT_APP_SERVERSIDE_BASE_API;

const PaymentFormWraperSubscribe = ({bump, name, email, onBumpChange, onStepChange}) => {

    // console.log(name, email)
    const [stripePromise, setStripePromise] = useState(null);
    // const [clientSecret, setClientSecret] = useState("");
    // const [stripeCustomerId, setStripeCustomerId] = useState("");
    // const [stripePaymentIntent, setStripePaymentIntent] = useState("");
    // const [stripeSubscription, setStripeSubscription] = useState("");
    
   /**  Get stripe publishable key from the server's .env 
   * use publishable key to loadStripe **/    
    useEffect(() => {
      const fetchPublishableKey = async (req, res)=>{
          try{
              const response = await axios.get(serverSideBaseAPI +'/buy/config');
              const {publishableKey} = response.data;
              setStripePromise(loadStripe(publishableKey))
          }catch(error){
              return res.status(400).send({
                  error: {
                      message: error.message
                  }
              }) 
          }
      }
      fetchPublishableKey();
    }, [])

    /** Create paymentIntent **/
    // useEffect(() => {
    //   const createPaymentIntent = async (req, res)=>{
    //       try{
    //           // grab client secret from res.data
    //           const {data} = await axios.post(serverSideBaseAPI + '/buy/subscribe', {name, email})
    //           console.log(data)
    //           setClientSecret(data.clientSecret)
    //           setStripeCustomerId(data.customer)
    //           setStripePaymentIntent(data.intentId)
    //           setStripeSubscription(data.subscription)
    //       }catch(error){
    //           return res.status(400).send({
    //               error: {
    //                   message: error.message
    //               }
    //           })
    //       }
    //   }
    //   createPaymentIntent()
    //   },[])


    return(
        <>
        {
        stripePromise && 
            (
                <Elements stripe={stripePromise} >
                    <PaymentFormSubscribe 
                        bump={bump} 
                        name={name}
                        email={email}
                        onBumpChange={onBumpChange} 
                        onStepChange={onStepChange}
                        // sub={stripeSubscription}
                        // intentId={stripePaymentIntent}
                        />
                </Elements>
            )
        }
        </>
    )
}

export default PaymentFormWraperSubscribe