export const quizContent = [
    {
        type:'Weight loss challenges',
        statement:'Everyone experiences challenges on their individual weight loss journey. Let\'s create successful strategy to power through.',
        question: 'Select one statement that rings most true to you.',
        answers: [
            {
                type:"situations",
                content: "It’s hard to stick with my weight loss plan when I’m going out"
            },
            {
                type: "stress",
                content: "I always get derailed when I’m feeling blue or stressed"
            },
            {
                type: "social pressure",
                content: " I can’t follow a diet when friends or family have different eating habits"
            },
            {
                type: "cravings",
                content: "Food cravings prevent me from achieving my weight loss goal"
            }
        ]
    },
    {
        type:'Sleep issue',
        statement:'Understanding the relationship between your weight and quality of your sleep will help you boost your body’s natural ability to burn off excess fat.',
        question: 'Is your sleep restful?',
        answers: [
            {
                type:"good",
                content: "Pretty good. I wake up rested and refreshed"
            },
            {
                type: "poor",
                content: "My sleep quality is poor. I wake up more than once at night"
            },
            {
                type: "trouble",
                content: "I regularly have trouble falling asleep and staying asleep"
            },
            {
                type: "toss all night",
                content: "I toss and turn almost all night. I have trouble shutting my mind off"
            }
        ]
    },
    {
        type: "Problem areas",
        statement: "The way your body stores fat is a direct reflection of how certain \"fat burning\" hormones are performing.",
        question: "Which of the following is MOST true for you?",
        answers: [
            {
                type: "lower body",
                content: "I hold most of my extra weight in the lower half (buttocks, hips and thighs)"
            },
            {
                type: "mid section",
                content: "I have more of an apple shape figure (midsection is my problem area)"
            },
            {
                type: "whole body",
                content: "My extra weight is evenly distributed throughout my body"
            },
            {
                type: "lose few pounds",
                content: "I have more of an hourglass figure but could still lose a couple of pounds"
            }
        ]
    },
    {
        type: "how weight was gained",
        statement: "How the weight was gained can help us determine the best way to lose it.",
        question: "Which situation can you relate to?",
        answers: [
            {
                type: "was always heavy",
                content: "I have battled with extra weight my whole life"
            },
            {
                type: "no issues till recently",
                content: "I haven't had any issues controlling my weight until recent couple of years"
            },
            {
                type: "gained after birth control or depression medications",
                content: "I have gained weight after starting either birth control and/or depression medication"
            },
            {
                type: "after giving birth",
                content: "My ability to lose weight changed after giving birth to my first child"
            }
        ]
    },
    {
        type: "mood",
        statement: "How we feel throughout the day can often be an indicator that your body doesn’t get all the necessary nutrients.",
        question: "Which best describes your mood and energy levels on a daily basis?",
        answers: [
            {
                type: "good mood and energy",
                content: "I'm pretty happy and have consistent energy throughout the day"
            },
            {
                type: "more tired than I used to be",
                content: "In the last 2-3 years I feel more tired than I used to be"
            },
            {
                type: "good days mixed with bad",
                content: "I have good days (happy, energetic) mixed with bad days (fatigue, feeling depressed)"
            },
            {
                type: "use medication to manage",
                content: "I use medication and/or supplements to help with mood and energy"
            }
        ]
    },
    {
        type: "cravings",
        statement: "Understanding what your body is craving will help us create most efficient weight loss strategy.",
        question: "Do you get cravings for certain types of foods?",
        answers: [
            {
                type: "every day",
                content: "Yes, at least one time every day"
            },
            {
                type: "sometimes",
                content: "Maybe once or twice a week"
            },
            {
                type: "very rare",
                content: "Only sometimes, once a month or less"
            }
        ]
    },
    {
        type: "weight loss challenge",
        statement: "Knowing the biggest challenge you face on your weight loss journey will help us create a plan specific to your unique situation.",
        question: "Which of the following is the most relevant to you?",
        answers: [
            {
                type: "hit plateau",
                content: "I usually lose weight easily at the beginning but soon I hit a plateau"
            },
            {
                type: "intense cravings",
                content: "I experience intense cravings or mood swings while losing weight"
            },
            {
                type: "headaches, fatigue, poor sleep",
                content: "I feel fatigued and often experience headaches or poor sleep during weight loss"
            },
            {
                type: "slow weight loss",
                content: "I lose weight very slowly even when dieting or starting a new exercise program"
            }
        ]
    },
    {
        type: "schedule",
        statement: "Your daily schedule can be a big burden on your commitment to the program.",
        question: "How busy are you?",
        answers: [
            {
                type: "very busy",
                content: "I barely have any time for myself"
            },
            {
                type: "busy but make time",
                content: "I'm busy but try to reserve some time each day to relax and unwind"
            },
            {
                type: "not too busy",
                content: "I'm not too busy and keep time open for different things"
            },
            {
                type: "not busy at all",
                content: "My schedule is fairly open and flexible"
            }
        ]
    },
    {
        type: "goal",
        statement: "Do you have a goal that you want to achieve when you reach your desired weight?",
        question: "What would you want to achieve?",
        answers: [
            {
                type: "Be able to run 5K",
                content: "I want to run 5K or more"
            },
            {
                type: "Have fun with family outside",
                content: "Be able to do outdoor activities with family"
            },
            {
                type: "Feel confident in your body",
                content: "Feel comfortable and confident in my body"
            },
            {
                type: "Feel amazing in old jeans",
                content: "Zip up an old pair of jeans"
            }
        ]
    },
    {
        type: "gender",
        statement: "Sex and hormones impact how our bodies metabolize food. ",
        question: "Please choose one",
        answers: [
            {
                type: "male",
                content: "I'm a Male"
            },
            {
                type: "female",
                content: "I'm a Female"
            }
        ]
    },
    {
        type: "activity",
        statement: "Which activity level best describes your lifestyle?",
        question: "Select one",
        answers: [
            {
                type: "sedentary",
                content: "I do little or no exercise"
            },
            {
                type: "light",
                content: "I do light exercise or sports 1-3 days/week"
            },
            {
                type: "moderate",
                content: "I do moderate exercise or sports 3-5 days/week"
            },
            {
                type: "very",
                content: "I do hard exercise or sports 6-7 days a week"
            },
            {
                type:"extra",
                content:"I do very hard exercise or sports and physical job"
            }
        ]
    }
  ];
// [
//     {
//         type:'Weight loss challenges',
//         statement:'Everyone experiences challenges that are specific to their individual weight loss journey. Those who are successful at reaching their weight loss goals are the ones who have a strategy to break through their weight loss barriers.',
//         question: 'Select one statement that rings most true to you.',
//         answers: [
//             {
//                 type:"social situations",
//                 content: "It’s always been a challenge for me to stick with my weight loss plan when I’m going out to eat, at a party or on vacation."
//             },
//             {
//                 type: "stress",
//                 content: "I can not follow a diet when I’m feeling blue or stressed. "
//             },
//             {
//                 type: "social pressure",
//                 content: "I can not follow a weight loss plan when my food patterns differ from the eating habits of my family or friends."
//             },
//             {
//                 type: "cravings",
//                 content: "Food cravings are my biggest challenge that prevent me from achieving my weight loss goal."
//             }
//         ]
//     },
//     {
//         type:'Sleep issue',
//         statement:'Understanding the relationship between your weight and quality of your sleep will help you increase your efficiency levels and boost your body’s natural ability to burn off excess fat.',
//         question: 'Is your sleep restful?',
//         answers: [
//             {
//                 type:"good",
//                 content: "Pretty good. I wake up rested and refreshed."
//             },
//             {
//                 type: "poor",
//                 content: "My sleep quality is poor. I wake up more than once at night."
//             },
//             {
//                 type: "trouble",
//                 content: "I regularly have trouble falling asleep and staying asleep."
//             },
//             {
//                 type: "toss all night",
//                 content: "I toss and turn almost all night. I have trouble shutting my mind off."
//             }
//         ]
//     },
//     {
//         type: "Problem areas",
//         statement: "The way our body stores fat is a direct reflection of how certain FAT BURNING hormones are performing. For example, hormonal imbalance can be the cause of weight gain around hips and thighs.",
//         question: "Which of the following is MOST true for you?",
//         answers: [
//             {
//                 type: "lower body",
//                 content: "I hold most of my extra weight in the lower half (buttocks, hips and thighs)"
//             },
//             {
//                 type: "mid section",
//                 content: "I have more of an apple shape figure (my midsection and tummy are my biggest problem areas)"
//             },
//             {
//                 type: "whole body",
//                 content: "My extra weight is evenly distributed throughout my body"
//             },
//             {
//                 type: "lose few pounds",
//                 content: "I have more of an hourglass figure but could still lose a couple of pounds"
//             }
//         ]
//     },
//     {
//         type: "how weight was gained",
//         statement: "How the weight was gained is often a critical factor in determining the best way to lose it.",
//         question: "Which situation can you relate the most to?",
//         answers: [
//             {
//                 type: "was always heavy",
//                 content: "I have always carried extra weight and I have battled with weight loss my whole life."
//             },
//             {
//                 type: "no issues till recently",
//                 content: "I have never had any issues controlling my weight until recent couple of years."
//             },
//             {
//                 type: "gained after birth control or depression medications",
//                 content: "I noticed more weight gain after starting either birth control and/or depression medication."
//             },
//             {
//                 type: "after giving birth",
//                 content: "My ability to lose weight changed after giving birth to my first child."
//             }
//         ]
//     },
//     {
//         type: "mood",
//         statement: "Our general mood and how we feel throughout the day often can be an indicator how well our WEIGHT LOSS hormones are performing.",
//         question: "Which best describes your mood and energy levels on a daily basis?",
//         answers: [
//             {
//                 type: "good mood and energy",
//                 content: "I'm pretty happy and have consistent energy throughout the day."
//             },
//             {
//                 type: "more tired than I used to be",
//                 content: "In the last 2-3 years I feel more tired than I used to be."
//             },
//             {
//                 type: "good days mixed with bad",
//                 content: "I have good days (happy, energetic) mixed with bad days (fatigue, feeling depressed)."
//             },
//             {
//                 type: "use medication to manage",
//                 content: "I use prescription medication and/or over-the-counter supplements to help with mood and energy."
//             }
//         ]
//     },
//     {
//         type: "cravings",
//         statement: "Understanding what your body is craving will help manage your cravings and create most efficient weight loss strategy.",
//         question: "Do you get cravings for certain types of foods?",
//         answers: [
//             {
//                 type: "every day",
//                 content: "Yes, every day."
//             },
//             {
//                 type: "sometimes",
//                 content: "Maybe once or twice a week."
//             },
//             {
//                 type: "very rare",
//                 content: "Only sometimes, once a month or less"
//             }
//         ]
//     },
//     {
//         type: "weight loss challenge",
//         statement: "Knowing the biggest challenge you face on your weight loss journey will help us create recommendations specific to your unique situation.",
//         question: "Which of the following is the most accurate statement?",
//         answers: [
//             {
//                 type: "hit plateau",
//                 content: "I usually lose weight easily at the beginning but soon I hit a plateau."
//             },
//             {
//                 type: "intense cravings",
//                 content: "I experience intense cravings and/or mood swings while losing weight."
//             },
//             {
//                 type: "headaches, fatigue, poor sleep",
//                 content: "I feel fatigued and often experience headaches and/or poor sleep during weight loss."
//             },
//             {
//                 type: "slow weight loss",
//                 content: "I lose weight very slowly even when dieting or starting a new exercise program."
//             }
//         ]
//     },
//     {
//         type: "schedule",
//         statement: "Your daily schedule can be a big burden on your commitment to the program and ultimately play a critical role in your weight loss success.",
//         question: "How busy are you?",
//         answers: [
//             {
//                 type: "very busy",
//                 content: "I barely have any time for myself."
//             },
//             {
//                 type: "busy but make time",
//                 content: "I'm busy but try to reserve some time each day to relax and unwind."
//             },
//             {
//                 type: "not too busy",
//                 content: "I'm not too busy and keep time open for different things."
//             },
//             {
//                 type: "not busy at all",
//                 content: "My schedule is fairly open and flexible."
//             }
//         ]
//     },
//     {
//         type: "age group",
//         statement: "Depending on your age, there are certain nutrition and exercise principles that you should avoid if you want to succeed. For example, low carb and low calorie diets may be great for 18-25 year olds, but can create adverse effect if you are in your 30's or 40’s.",
//         question: "What age range are you in?",
//         answers: [
//             {
//                 type: "under 30",
//                 content: "18-29 years old"
//             },
//             {
//                 type: "30-40",
//                 content: "30-40 years old"
//             },
//             {
//                 type: "40-60",
//                 content: "41-59 years old"
//             },
//             {
//                 type: "over 65",
//                 content: "65 or older"
//             }
//         ]
//     }
//   ];
  
