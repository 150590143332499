import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {
  Login, 
  Landing, 
  KetoPizzaLanding,
  KetoPizzaLandingThankYou,
  Home, 
  Register, 
  Quiz,
  CheckoutSubscribe,
  LegalPages,
  ForgotPassword,
  PageNotFoundRedirect,
  QuizzesMain
  // TermsConditions
} from './pages';

import './App.css'

const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/home/*' element={<Home />} />
          <Route path='/login' element={<Login />}/>
          <Route path='/register' element={<Register />} />
          
          {/* Langing pages  */}
          <Route path='/ketopizza' element={< KetoPizzaLanding/>} />
          <Route path='/ketopizzaty' element={<KetoPizzaLandingThankYou/>}/>
          <Route path='/' element={<Landing />}/>
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/quizzes/*' element={<QuizzesMain/>}/>
          {/* <Route path='' element={</>}/> */}

          {/* ******* CHECKOUT ROUTES ******** */}
          {/* /checkout is working route for single purchase and purchse with bump */}
          {/* <Route path='/checkout' element={<Checkout/>}/> */}

          {/* /subscribe is working route for 12 week subscription and subscription with bump */}
          <Route path='/subscribe' element={<CheckoutSubscribe/>}/>
          {/* <Route path='/subscriberedirect' element={<SubscribeOrderBump/>} /> */}
          {/* <Route path='/checkoutcard' element={<CheckoutCard/>}/> */}

          <Route path='/legal/:page' element={<LegalPages/>}/>

          <Route path='/reset' element={<ForgotPassword/>} />
          <Route path='/reset/password' element={<ForgotPassword/>}/>

          <Route path='*' element={<PageNotFoundRedirect/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App