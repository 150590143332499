import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import buyerService from './buyerService';

// get user from localStorage
const buyer = JSON.parse(localStorage.getItem('buyer'));

const initialState = {
    data: buyer ? buyer: null,
    isError: false,
    isSuccess: false,
    isLoading:false, 
    message: ''
}
// Create new buyer record Thunk
export const newBuyer = createAsyncThunk('buyer/new',
async (buyer, thunkAPI) => {
    try{
        return await buyerService.newbuyer(buyer)
    }catch(error){
        const message = (error.response && 
                        error.response.data && 
                        error.response.data.message) || 
                        error.message ||
                        error.toString()
        
        return thunkAPI.rejectWithValue(message)                
    }
})

// Retrieve full buyer record THunk
export const fullBuyerRecord = createAsyncThunk('buyer/fullRecord',
    async (buyer, thunkAPI) => {
        try{

            return await buyerService.buyerRecord(buyer)
        } catch (error){
            const message = (error.response && 
                error.response.data && 
                error.response.data.message) || 
                error.message ||
                error.toString()

            return thunkAPI.rejectWithValue(message) 
        }
    }
)

// Retrieve and return only buyerId Thunk
export const buyerRecord = createAsyncThunk('buyer/record',
    async (buyer, thunkAPI ) => {
        try{
            return await buyerService.buyer(buyer)
        } catch(error){
            const message = (error.response && 
                            error.response.data && 
                            error.response.data.message) || 
                            error.message ||
                            error.toString()
                
            return thunkAPI.rejectWithValue(message)                
        }
    }
)

export const buyerSlice = createSlice({
    name: 'buyer',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {

        builder
            .addCase(newBuyer.pending, (state) => {
                state.isLoading = true
            })
            .addCase(newBuyer.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(newBuyer.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.data = null
            })
            .addCase(buyerRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(buyerRecord.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(buyerRecord.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.data = null
            })
            .addCase(fullBuyerRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fullBuyerRecord.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(fullBuyerRecord.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.data = null
            })
    }
})

export const { reset } = buyerSlice.actions;
export default buyerSlice.reducer;