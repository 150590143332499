import axios from "axios";

// const API_URL = "http://localhost:5000/api/mealplan/";
// const API_URL = 'https://eattoburn.com/api/mealplan/';
const API_URL = process.env.REACT_APP_SERVERSIDE_BASE_API + '/mealplan/';


// Creat new mealplan record
const newMealplanRecord = async (mealplanData) => {
    const response = await axios.post(API_URL + 'newmealplanrecord/', mealplanData);

    if (response.data) {
        // save to localStorage as {"mealplanId":"xxxxxxx"}
        localStorage.setItem('mealplanId', JSON.stringify(response.data.mealplanId))
    }

    return response.data;
}

// Get mealplanrecord using buyerId
const mealplanRecord = async (buyerId) => {
    /* Check if data is stored in localStorage */
    /* User local stored data otherwise fetch data again from API */
    const response = await axios.post(API_URL + 'mealplanrecord/', buyerId);
    return response.data;
}

// Update mealplan record 
const updateMealplanRecord = async (mealplanData) => {
    const response = await axios.put(API_URL + 'updatemealplanrecord/', mealplanData);
    return response.data
}

// const mealplanBuilder = async (mealplanParams, token) => {
//     console.log('mealplanServce', mealplanParams)
//     const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//     }

//     const response = await axios.post(API_URL + 'getmealplan/', mealplanParams, config);

//     // if(response.data){
//     //     localStorage.setItem('mealplanRecipes', JSON.stringify(response.data));
//     // }

//     return response.data
// }

const mealplanService = {
    newMealplanRecord,
    mealplanRecord,
    updateMealplanRecord
    // mealplanBuilder
}

export default mealplanService;